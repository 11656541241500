import { GoogleLogin } from '@react-oauth/google';
import Box from "@mui/material/Box";
import withWidth from "@mui/material/Hidden/withWidth";
import {useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import {useState} from "react";

const LoginDetails = ({_}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const getBatchIndex = (location) => {
        return location.state && location.state.hasOwnProperty(
            'batchIndex') && location.state.batchIndex
                    ? location.state.batchIndex : 0;
    }

    const getTitleLingo = (location) => {
        return location.state && location.state.hasOwnProperty(
            'titleLingo') && location.state.titleLingo
                    ? location.state.titleLingo : '';
    }

    const getEarlyLogin = (location) => {
        return location.state && location.state.hasOwnProperty(
            'earlyLogin') && location.state.earlyLogin
                    ? location.state.earlyLogin : false;
    }

    const [batchIndex] = useState(getBatchIndex(location));
    const [titleLingo] = useState(getTitleLingo(location));
    const [earlyLogin] = useState(getEarlyLogin(location));

    return (
        <Box display='flex' justifyContent='center' alignItems='center'>
            <GoogleLogin
                onSuccess={
                    (credentialResponse) => {
                        axios.post(
                            '/api/login', `${credentialResponse.credential}`, {
                                headers: {'Content-Type': 'text/plain;charset=UTF-8'},
                                withCredentials: true
                            }
                        ).then(
                            _ => {
                                // https://chromium.googlesource.com/chromium/src/+/c0b6446eb589b0f23885b0705b69c45932ea6313%5E%21/
                                if (!earlyLogin) {
                                    navigate(-1);
                                } else {
                                    console.log('earlyLogin: ' + earlyLogin)
                                    console.log('batchIndex: ' + batchIndex)
                                    console.log('titleLingo: ' + titleLingo)

                                    navigate('/', {
                                        state: {
                                            batchIndex: batchIndex,
                                            titleLingo: titleLingo
                                        },
                                        replace: true
                                    })
                                }
                            }
                        );
                    }
                }
                onError={
                    () => {
                        console.log('Login Failed');
                    }
                }
                useOneTap
            />
        </Box>
    );
}

export default withWidth()(LoginDetails);