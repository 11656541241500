import axios from 'axios';

export const fetchActorDetails = async (
    musicActor) => {
    const response = await axios.get(
        '/api/actorDetails', {
            params: {
                actorKey: musicActor
            },
            withCredentials: true
        });
    return response.data;
};
