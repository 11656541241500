import React from 'react';
import {Avatar, CardMedia, Stack, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const VideoContent = ({ video, panel, onVideoSelected, onOwnerSelected }) => {
    return (
        <Grid xs={12} container rowSpacing={2}
              style={{cursor: 'pointer'}}
              onClick={() => onVideoSelected(video)}>

            <Grid xs={12}>
                <CardMedia
                    component='img'
                    image={video.videoThumb[1] ? video.videoThumb[1] : video.videoThumb[0]}
                    alt  ={`${video.videoTitle} thumbnail`}
                    title={`${video.videoTitle} thumbnail`}
                    sx={{ borderRadius: {xs: '0px', sm: '12px'} }}
                />
            </Grid>

            <Grid container xs={12}>
                <Grid xs='auto' sx={{px: 1, py: 1}}>
                    <Avatar
                        src={video.ownerThumb[0]}
                        alt  ={video.ownerTitle}
                        title={video.ownerTitle}
                        sx={{ width: 36, height: 36 }}
                        onClick={(e) => {e.stopPropagation(); onOwnerSelected(video)}}
                    />
                </Grid>

                <Grid xs sx={{py: 1}}>
                    <Stack>
                        <Typography sx={{ fontWeight: 600, color: '#000000' }}>
                            {video.trackTitle + ' - ' + (panel === 0 ? video.ownerTitle : video.actorTitle)}
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>

        </Grid>
    );
};

export default VideoContent;
