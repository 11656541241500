import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { GoogleOAuthProvider } from "@react-oauth/google"

ReactDOM.createRoot(document.getElementById('root')).render(
    <GoogleOAuthProvider clientId='2523546438-o02eon518vb5rst0h0g83bsdpka50knm.apps.googleusercontent.com'>
        <App />
    </GoogleOAuthProvider>
)

/*
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
*/
