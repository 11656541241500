import axios from 'axios';

export const fetchTitleDetails = async (
    trackTitle,
    actorTitle,
    classTrait,
    styleTrait,
    watchVideo) => {
    const response = await axios.get(
        '/api/titleDetails', {
            params: {
                trackTitle,
                actorTitle,
                classTrait,
                styleTrait,
                watchVideo
            },
            withCredentials: true
        });

    return response.data;
};
