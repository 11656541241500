import React from 'react';
import {CardMedia, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const MediaContent = ({ video, onVideoSelected }) => {
    return (
        <Grid xs={12} container rowSpacing={2}
              style={{ cursor: 'pointer' }}
              onClick={() => onVideoSelected(video)}>
            <Grid xs={12}>
                <CardMedia
                    component='img'
                    image={video.videoThumb[1] ? video.videoThumb[1] : video.videoThumb[0]}
                    alt  ={`${video.videoTitle} thumbnail`}
                    title={`${video.videoTitle} thumbnail`}
                    sx={{ borderRadius: {xs: '0px', sm: '12px'} }}
                />
            </Grid>

            <Grid xs={12} sx={{pl: 1}}>
                <Typography noWrap sx={{fontWeight: 600}}>
                    {video.trackTitle + ' - ' + video.actorTitle}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default MediaContent;
