import React, {useEffect, useRef, useState} from 'react';
import VideoContent from "../components/VideoContent";
import {fetchTitleDetails} from "../api/TitleDetails";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {Chip, Container, Fab, Stack} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import TitleSuggest from "../components/TitleSuggest";
import WatchContent from "../components/WatchContent";
import withWidth from "@mui/material/Hidden/withWidth";
import QueueContent from "../components/QueueContent";
import {LibraryMusic} from "@mui/icons-material";

const TitleDetails = ({width}) => {
    const styleRef = useRef();
    const location = useLocation();
    const navigate = useNavigate();

    const [queryParam, setQueryParam] = useSearchParams();

    const getTrackTitle = (location) => {
        return location.state && location.state.hasOwnProperty(
            'trackTitle') && location.state.trackTitle
                    ? location.state.trackTitle : '';
    }

    const getActorTitle = (location) => {
        return location.state && location.state.hasOwnProperty(
            'actorTitle') && location.state.actorTitle
                    ? location.state.actorTitle : '';
    }

    const getClassTrait = (location) => {
        return location.state && location.state.hasOwnProperty(
            'classTrait') && location.state.classTrait
                    ? location.state.classTrait : '';
    }

    const getStyleTrait = (location) => {
        return location.state && location.state.hasOwnProperty(
            'styleTrait') && location.state.styleTrait
                    ? location.state.styleTrait : '';
    }

    const getMusicPanel = (location) => {
        return location.state && location.state.hasOwnProperty(
            'musicPanel') && location.state.musicPanel
                    ? location.state.musicPanel :  0;
    }

    const getWatchQueue = (location) => {
        return location.state && location.state.hasOwnProperty(
            'watchQueue') && location.state.watchQueue
                    ? location.state.watchQueue : [];
    }

    const getWatchVideo = (location) => {
        return location.state && location.state.hasOwnProperty(
            'watchVideo') && location.state.watchVideo
                    ? location.state.watchVideo
                            : queryParam.get('v');
    }

    const [trackTitle, setTrackTitle] = useState(
        getTrackTitle(location));

    const [actorTitle, setActorTitle] = useState(
        getActorTitle(location));

    const [classTrait, setClassTrait] = useState(
        getClassTrait(location));

    const [styleTrait, setStyleTrait] = useState(
        getStyleTrait(location));

    const [watchVideo, setWatchVideo] = useState(
        getWatchVideo(location));

    const [musicPanel, setMusicPanel] = useState(
        getMusicPanel(location));

    const [watchQueue, setWatchQueue] = useState(
        getWatchQueue(location));

    if (trackTitle !== getTrackTitle(location) ||
        actorTitle !== getActorTitle(location) ||
        classTrait !== getClassTrait(location) ||
        styleTrait !== getStyleTrait(location) ||
        watchVideo !== getWatchVideo(location)) {

        setTrackTitle (getTrackTitle(location));
        setActorTitle (getActorTitle(location));
        setClassTrait (getClassTrait(location));
        setStyleTrait (getStyleTrait(location));
        setWatchVideo (getWatchVideo(location));
        setMusicPanel (getMusicPanel(location));
        setWatchQueue (getWatchQueue(location));
    }

    const [title, setTitle] = useState(
        {
            trackTitle: trackTitle,
            actorTitle: actorTitle,
            titleStyleTotal: {}
        }
    );

    const [watchBlock, setWatchBlock] = useState([]);
    const [coverBlock, setCoverBlock] = useState([]);
    const [musicBlock, setMusicBlock] = useState([]);

    useEffect(() => {
        const updatePage = async (
                trackTitle,
                actorTitle,
                classTrait,
                styleTrait,
                watchVideo,
                musicPanel,
                watchQueue) => {
            try {
                console.log('trackTitle: ' + trackTitle)
                console.log('actorTitle: ' + actorTitle)
                console.log('classTrait: ' + classTrait)
                console.log('styleTrait: ' + styleTrait)
                console.log('watchVideo: ' + watchVideo)

                const response = await fetchTitleDetails(
                    trackTitle,
                    actorTitle,
                    classTrait,
                    styleTrait,
                    watchVideo
                );

                setTrackTitle(trackTitle);
                setActorTitle(actorTitle);
                setClassTrait(classTrait);
                setStyleTrait(styleTrait);
                setWatchVideo(watchVideo);

                setMusicPanel(musicPanel);
                setWatchQueue(
                    watchQueue.length > 0
                        ? watchQueue
                        : musicPanel === 0
                                ? response.titleSimilar
                                : response.titleContent
                        );

                setTitle(
                    response.titleSummary[0]);

                setCoverBlock(
                    response.titleContent);
                setMusicBlock(
                    response.titleSimilar);
                setWatchBlock(
                    response.watchContent);

                if (!queryParam.get('v')) {
                    const queryParam = new URLSearchParams();
                    queryParam.set(
                        'v', response.watchContent[0].videoKey);
                    setQueryParam(queryParam);
                }

                if (styleRef.current) {
                    styleRef.current.scrollTo(
                        {left: 0});
                }
            } catch (error) {
                if (error?.response?.status === 401) {
                    navigate('/ld')
                } else {
                    console.error(
                        "Error fetching title details: ", error);
                }
            }
        };

        updatePage(
            trackTitle,
            actorTitle,
            classTrait,
            styleTrait,
            watchVideo,
            musicPanel,
            watchQueue).then();
    }, [
        trackTitle,
        actorTitle,
        classTrait,
        styleTrait,
        watchVideo,
        musicPanel,
        watchQueue,
        queryParam,
        setQueryParam]
    );

    const onVideoFinished = (onError) => {
        if (onError === 0) {
            let replace = 0;
            onPlayNextQueue(replace);
        }
    }

    const onPlayPrevQueue = () => {
        navigate(-1);
    }

    const onPlayNextQueue = (replace = 0) => {
        let queueIndex = getNextQueueIndex(watchQueue);
        if (queueIndex >= 0 && queueIndex < watchQueue.length) {
            navigateToQueue(
                watchQueue[queueIndex], replace);
        }
    }

    const getNextQueueIndex = (watchQueue) => {
        return musicPanel === 0
            ? getNextTitleIndex(watchQueue)
            : getNextVideoIndex(watchQueue);
    }

    const getNextVideoIndex = (videoBlock) => {
        let videoIndex = videoBlock.findIndex((video) => {
            return video.videoKey === watchVideo;
        })
        return videoIndex < 0 ? 0 : (videoIndex + videoBlock.length + 1) % videoBlock.length;
    }

    const getNextTitleIndex = (titleBlock) => {
        let titleIndex = titleBlock.findIndex((video) => {
            return video.trackTitle === trackTitle
                && video.actorTitle === actorTitle;
        })
        return titleIndex < 0 ? 0 : (titleIndex + titleBlock.length + 1) % titleBlock.length;
    }

    const onInputSelected = (
        video) => {
        let replace = 0;
        navigateToInput(
            video, replace);
    };

    const onVideoSelected = (video) => {
        let replace = 0;
        navigateToVideo(video, replace);
    };

    const onOwnerSelected = (video) => {
        let replace = 0;
        navigateToOwner(video, replace);
    };

    const onStyleSelected = (style) => {
        let replace = 1;
        navigateToStyle(style, replace);
    };

    const navigateToInput = (
        video, replace) => {
        let trackTitle = '';
        let actorTitle = '';
        let classTrait = '';
        let styleTrait = '';
        let watchVideo = video;

        navigate(`/td?v=${watchVideo}`,
            {
                state: {
                    trackTitle,
                    actorTitle,
                    classTrait,
                    styleTrait,
                    watchVideo,
                },
                replace: replace
            });
    };

    const navigateToVideo = (
        video, replace) => {
        let trackTitle = video.trackTitle
        let actorTitle = video.actorTitle;
        let watchVideo = video.videoKey;

        navigate(`/td?v=${watchVideo}`,
            {
                state: {
                    trackTitle,
                    actorTitle,
                    classTrait,
                    styleTrait,
                    watchVideo,
                    musicPanel,
                },
                replace: replace
            });

        window.scrollTo({top: 0, left: 0});
    };

    const navigateToQueue = (
        video, replace) => {
        let trackTitle = video.trackTitle
        let actorTitle = video.actorTitle;
        let watchVideo = video.videoKey;

        navigate(`/td?v=${
                watchVideo}`,
            {
                state: {
                    trackTitle,
                    actorTitle,
                    classTrait,
                    styleTrait,
                    watchVideo,
                    musicPanel,
                    watchQueue,
                },
                replace: replace
            });
    };

    const navigateToOwner = (
        video, replace) => {
        let videoOwner = video.ownerKey;

        navigate(`/ad?a=${
                videoOwner}`,
            {
                replace: replace
            });
    };

    const navigateToStyle = (
        style, replace) => {
        let styleTrait = style;

        navigate(`/td?v=${
            watchVideo}`,
            {
                state: {
                    trackTitle,
                    actorTitle,
                    classTrait,
                    styleTrait,
                    watchVideo,
                    musicPanel,
                },
                replace: replace
            });
    };

    const styleLabelString = ( style, count ) => {
        return `${style.toUpperCase()} (${count})`;
    }

    const styleColorString = ( style, _ ) => {
        return style === styleTrait
            ? 'secondary' : 'primary'
    }

    const styleTotalFilter = ([style, total]) => {
        return style === styleTrait
            || (style !== 'ai' && total >= 5);
    }

    const styleTotalSorter = (
        [style1, count1], [style2, count2]) => {
        return style1 === styleTrait
            ? -1 : style2 === styleTrait
                ? 1 : (count2 - count1);
    }

    const watchFilter = (video) => {
        return video.videoKey !== watchVideo;
    }

    const titleFilter = (video) => {
        return video.trackTitle !== title.trackTitle
            || video.actorTitle !== title.actorTitle;
    }

    const watchQueueFilter = () => {
        return musicPanel !== 0 ? watchFilter : titleFilter;
    }

    const isSmallScreen = /xs|sm/.test(width);

    if (isSmallScreen) {
        return (
            <React.Fragment sx={{ position: 'relative' }}>
                <TitleSuggest key={watchVideo} onInputSelected={onInputSelected}/>

                <Stack>
                    {watchBlock?.map((video, index) => (
                        <WatchContent
                            key={index} title={title} video={video}
                            onOwnerSelected={onOwnerSelected}
                            onVideoFinished={onVideoFinished}
                            onPlayPrevVideo={onPlayPrevQueue}
                            onPlayNextVideo={onPlayNextQueue}
                        />
                    ))}

                    {
                        Object.entries(title.titleStyleTotal).filter(styleTotalFilter).length > 0 &&
                        <Grid container xs={12} ref={styleRef} flexWrap='nowrap' sx={{ overflowX: 'auto', py: 2 }}>
                            {Object.entries(title.titleStyleTotal)
                                .filter(styleTotalFilter).sort(styleTotalSorter)?.map(
                                    ([style, total], index) => (
                                        <Grid xs='auto' key={index} sx={{px: 1}}>
                                            <Chip
                                                color={styleColorString(style, total)}
                                                label={styleLabelString(style, total)}
                                                onClick={() => {onStyleSelected(
                                                    style === styleTrait ? '' : style)}}/>
                                        </Grid>
                                    )
                                )}
                        </Grid>
                    }

                    {
                        (coverBlock.length > 1 || musicBlock.length > 1) &&
                        <Grid container xs={12}>
                            {(musicPanel === 0
                                    ? coverBlock.filter(watchFilter)
                                    : musicBlock.filter(titleFilter)
                            )?.map((blockVideo, blockIndex) => (
                                <Grid key={blockIndex} xs={12} sm={6} lg={4} sx={{ px: {xs: 0, sm: 2}, py: {xs: 2} }}>
                                    <VideoContent
                                        video={blockVideo}
                                        panel={musicPanel}
                                        onVideoSelected={onVideoSelected}
                                        onOwnerSelected={onOwnerSelected}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    }
                </Stack>

                {
                    (coverBlock.length > 1 || musicBlock.length > 1) &&
                    <Fab sx = {{ position: 'fixed', bottom: 30, right: 30, border: 2 }}
                         color = 'primary'
                         onClick = {() => {
                             setWatchQueue(musicPanel === 0
                                 ? coverBlock : musicBlock)
                             setMusicPanel(musicPanel === 0 ? 1 : 0)
                         }}>
                        <LibraryMusic/>
                    </Fab>
                }

            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <TitleSuggest key={watchVideo} onInputSelected={onInputSelected}/>

                <Container maxWidth='xl'>

                    <Grid container spacing={1} >
                        <Grid md>
                            <Stack>
                                {watchBlock?.map((video, index) => (
                                    <WatchContent
                                        key={index} title={title} video={video}
                                        onOwnerSelected={navigateToOwner}
                                        onVideoFinished={onVideoFinished}
                                        onPlayPrevVideo={onPlayPrevQueue}
                                        onPlayNextVideo={onPlayNextQueue}
                                    />
                                ))}

                                {
                                    Object.entries(title.titleStyleTotal).filter(styleTotalFilter).length > 0 &&
                                    <Grid container xs={12} ref={styleRef} flexWrap='nowrap' sx={{ overflowX: 'auto', py: 2 }}>
                                        {Object.entries(title.titleStyleTotal)
                                            .filter(styleTotalFilter).sort(styleTotalSorter)?.map(
                                                ([style, total], index) => (
                                                    <Grid xs='auto' key={index} sx={{px: 1}}>
                                                        <Chip
                                                            color={styleColorString(style, total)}
                                                            label={styleLabelString(style, total)}
                                                            onClick={() => {onStyleSelected(
                                                                style === styleTrait ? '' : style)}}/>
                                                    </Grid>
                                                )
                                            )}
                                    </Grid>
                                }

                                {
                                    (coverBlock.length > 1 || musicBlock.length > 1) &&
                                    <Grid container xs={12} sx={{ position: 'relative' }}>
                                        <Fab sx = {{ position: 'absolute', top: 30, right: 30, border: 2 }}
                                             color = 'primary'
                                             onClick = {() => {
                                                 setWatchQueue(musicPanel === 0
                                                     ? coverBlock : musicBlock)
                                                 setMusicPanel(musicPanel === 0 ? 1 : 0)
                                             }}>
                                            <LibraryMusic/>
                                        </Fab>

                                        {(musicPanel === 0
                                            ? coverBlock.filter(watchFilter)
                                            : musicBlock.filter(titleFilter)
                                        )?.map((blockVideo, blockIndex) => (
                                            <Grid key={blockIndex} xs={12} sm={6} lg={4} sx={{ px: {xs: 0, sm: 2}, py: {xs: 2} }}>
                                                <VideoContent
                                                    video={blockVideo}
                                                    panel={musicPanel}
                                                    onVideoSelected={onVideoSelected}
                                                    onOwnerSelected={onOwnerSelected}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                }
                            </Stack>
                        </Grid>

                        {
                            (watchQueue.filter(watchQueueFilter()).length > 1) &&
                            <Grid md='auto' style={{width: '340px'}}>
                                {watchQueue.filter(watchQueueFilter())?.map(
                                    (queueVideo, queueIndex) => (
                                        <Grid key={queueIndex} xs={12} sx={{ px: {xs: 1}, pb: {xs: 1} }}>
                                            <QueueContent
                                                video={queueVideo}
                                                panel={musicPanel}
                                                onVideoSelected={onVideoSelected}
                                                onOwnerSelected={onOwnerSelected}
                                            />
                                        </Grid>
                                    ))}
                            </Grid>
                        }
                    </Grid>

                </Container>
            </React.Fragment>
        );
    }
};

export default withWidth()(TitleDetails);