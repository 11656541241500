import React from 'react';
import ReactPlayer from "react-player";
import Grid from "@mui/material/Unstable_Grid2";
import {Avatar, Stack, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import PlayPrevVideoIcon from '@mui/icons-material/SkipPrevious';
import PlayNextVideoIcon from '@mui/icons-material/SkipNext';
import {AvatarGroup} from "@mui/material";

const WatchContent = (
    {
        title,
        video,
        onOwnerSelected,
        onVideoFinished,
        onPlayPrevVideo,
        onPlayNextVideo
    }) => {

    const formatVideoViews = (video) => {
        let T = video && video.hasOwnProperty('videoViews')
            ? video.videoViews : 0;

        let K = Math.floor(T / 1000);
        let M = Math.floor(K / 1000);
        let B = Math.floor(M / 1000);

        return B >= 3 ? `${B}B` : M >= 3 ? `${M}M` : K >= 3 ? `${K}K` : T % 1000;
    }

    return (
        <Grid container xs={12}>
            <Grid xs={12} style={{position: 'relative', paddingBottom: '56.25%'}}>
                <ReactPlayer
                    controls={true}
                    playing ={true}
                    loop={false}
                    onEnded ={() => {onVideoFinished(0)}}
                    onError ={() => {onVideoFinished(1)}}
                    url={'https://www.youtube.com/watch?v=' + video.videoKey}
                    style={{position: 'absolute', top: '0', left: '0'}}
                    width ='100%'
                    height='100%'
                />
            </Grid>

            <Grid xs={12} container alignItems='flex-start'>
                <Grid xs='auto' sx={{px: 1, py: 1}}>
                    <AvatarGroup
                        total={title.titleTotal}
                        sx={{ px: 1, '& .MuiAvatar-root': { width: 36, height: 36, fontSize: 12, bgcolor: '#7b1fa2' } }}
                    >
                        <Avatar
                            src={video.ownerThumb[0]}
                            alt  ={video.ownerTitle}
                            title={video.ownerTitle}
                            sx={{ width: 36, height: 36 }}
                            style={{ cursor: 'pointer' }}
                            onClick={() => onOwnerSelected(video)}
                        />
                    </AvatarGroup>
                </Grid>

                <Grid xs sx={{py: 1}}>
                    <Stack>
                        <Typography noWrap sx={{ fontWeight: 600, color: '#000000' }}>
                            {title.trackTitle + ' - ' + title.actorTitle}
                        </Typography>

                        <Typography noWrap sx={{ fontWeight: 600, color: '#000000' }}
                                    style={{cursor: 'pointer'}}
                                    onClick={() => onOwnerSelected(video)}>
                            {video.ownerTitle}
                        </Typography>

                        <Typography noWrap sx={{ fontWeight: 400, color: '#666666' }}>
                            {video.videoTitle}
                        </Typography>
                    </Stack>
                </Grid>

                <Grid xs='auto' sx={{px: 1, pt: 1}} xsOffset='auto'>
                    <Stack alignItems='center'>
                        <Typography noWrap sx={{ fontWeight: 600 }}>
                            {formatVideoViews(video)}
                        </Typography>
                    </Stack>
                </Grid>

                <Grid xs='auto' sx={{px: 1, pt: 1}} xsOffset='auto'>
                    <ToggleButtonGroup >
                        <ToggleButton value='0' size='small' onClick={() => {onPlayPrevVideo()}}>
                            <PlayPrevVideoIcon />
                        </ToggleButton>
                        <ToggleButton value='1' size='small' onClick={() => {onPlayNextVideo()}}>
                            <PlayNextVideoIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>

        </Grid>
    );
};

export default WatchContent;
