import axios from 'axios';

export const fetchTitleSuggest = async (
    titleQuery) => {
    if (titleQuery.trim() === "") {
        return "{}"
    } else {
        const response = await axios.get(
            '/api/titleSuggest', {
                params: {
                    titleQuery
                },
                withCredentials: true
            });
        return response.data;
    }
};
