import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import ActorDetails from './pages/ActorDetails';
import LoginDetails from './pages/LoginDetails';
import TitleExplore from './pages/TitleExplore';
import TitleDetails from './pages/TitleDetails';


function App() {
  return (
    <Router>
        <Routes>
            <Route path="/"   element={<TitleExplore/>} />
            <Route path="/tx" element={<TitleExplore/>} />
            <Route path="/td" element={<TitleDetails/>} />
            <Route path="/ad" element={<ActorDetails/>} />
            <Route path="/ld" element={<LoginDetails/>} />
        </Routes>
    </Router>
  );
}

export default App;
